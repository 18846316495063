import * as types from './types';

let initState = {
    popout: null,
    categories: [],
    holidays: [],
    months: [],
    appearance: "dark",
    showAds: true
};

export default function sysReducer(state = initState, action) {
    switch (action.type) {
        case types.SYS_SET_POPOUT:
            return Object.assign({}, state, {popout: action.payload});
        case types.SYS_SET_SYS:
            return Object.assign({}, state, action.payload);
        case types.SYS_SET_CATEGORIES:
            return Object.assign({}, state, {categories: action.payload});
        case types.SYS_SET_HOLIDAYS:
            return Object.assign({}, state, {holidays: action.payload});
        default:
            return state;
    }
}