let $_GET = window
    .location
    .search
    .replace('?','')
    .split('&')
    .reduce(
        function(p,e){
            let a = e.split('=');
            p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
            return p;
        },
        {}
    );

if(process.env.NODE_ENV !== 'production') {
    $_GET = {
        vk_access_token_settings: "friends",
        vk_app_id: 7828475,
        vk_are_notifications_enabled: 1,
        vk_is_app_user: 1,
        vk_is_favorite: 1,
        vk_language: "ru",
        vk_platform: "mobile_android",
        vk_ref: "story258749517_3231_sdfsssfs_1_eyJpZF92ayI6NDQ1MzIyMTEsIm5hbWUiOiJNYXJpbmEifQ==",
        vk_user_id: 30333918,
        sign: "-otud6DVJf2anhxJd3uztapRXWBCkiVt7Ye97sGCBNI"
    };
}


export default $_GET;