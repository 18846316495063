import {
    Button, Div,
    Group,
    Panel,
    PanelHeader, PanelHeaderBack, Spinner,
    SubnavigationBar,
    SubnavigationButton
} from "@vkontakte/vkui";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState, useRef} from "react";
import axios from "../../utils/axios";
import getPathFiles from "../../helpers/getPathFiles";

import './Category.scss';
import ModalPost from "../../UI/ModalPost/ModalPost";
import ModalPostcard from "./ModalPostcard";

const Category = (props) => {
    const {id} = props;

    const [waitContent, setWaitContent] = useState(true);
    const [waitLoadMore, setWaitLoadMore] = useState(false);
    const [openedCard, setOpenedCard] = useState([]);
    const [activeOther, setActiveOther] = useState(null);
    const [arrOther, setArrOther] = useState([]);
    const [activeSex, setActiveSex] = useState(0);
    const [modalPost, setModalPost] = useState(false);

    const [p, setP] = useState({
        items: [],
        page: 1,
        loadMore: true
    });

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const reqParams = useRef({});

    const categories = useSelector(state => state.sys.categories);

    const count = 30;

    useEffect(() => {
        if(params.idCat && params.idSub) {
            if(categories[params.idCat]['subCategories'][params.idSub]['other']) {
                setArrOther(categories[params.idCat]['subCategories'][params.idSub]['other']);
            }
        }

    }, [categories, params.idCat, params.idSub]);
    
    useEffect(() => {
        if(params.idCat) {
            reqParams.current.id_category = categories[params.idCat]['id'];
        }
        if(params.idSub) {
            reqParams.current.id_sub = categories[params.idCat]['subCategories'][params.idSub]['id'];
        }

        axios.get("/api/postcards.get", {
            params: {...reqParams.current, count}
        }).then((res) => {
            let loadMore = true;
            if(res.data.response.postcards.length < count) {
                loadMore = false;
            }

            setP({
                items: res.data.response.postcards,
                page: 1,
                loadMore: loadMore
            });

            setWaitContent(false);
        }).catch((err) => {
            console.log(err);
            setWaitContent(false);
        });
    }, [categories, params.idCat, params.idSub]);

    function clickPostCard(idArr) {
        // navigate('/view-post');
        setOpenedCard(p.items[idArr]);
        setModalPost(true);
    }

    function clickOther(idArr) {
        setWaitContent(true);
        setActiveOther(idArr);
        // setActiveSex(0);

        setP({
            items: [],
            page: 1,
            loadMore: true
        });

        if(idArr !== null) {
            reqParams.current.id_other = categories[params.idCat]['subCategories'][params.idSub]['other'][idArr]['id'];
        }
        if(idArr === null) {
            delete reqParams.current.id_other;
        }
        
        axios.get("/api/postcards.get", {
            params: {...reqParams.current, count, sex: activeSex}
        }).then((res) => {
            let loadMore = true;
            if(res.data.response.postcards.length < count) {
                loadMore = false;
            }

            setP({
                items: res.data.response.postcards,
                page: 1,
                loadMore: loadMore
            });

            setWaitContent(false);
        }).catch((err) => {
            console.log(err);
            setWaitContent(false);
        });
    }

    function clickSex(sex) {
        setWaitContent(true);

        if(reqParams.current.id_other) {
            delete reqParams.current.id_other;
        }
        setActiveOther(null);
        // setArrOther(a);
        setActiveSex(sex);

        document.getElementsByClassName("HorizontalScroll__in")[0].scrollTo(0, 0);

        axios.get("/api/postcards.get", {
            params: {...reqParams.current, count, sex}
        }).then((res) => {
            let loadMore = true;
            if(res.data.response.postcards.length < count) {
                loadMore = false;
            }

            setP({
                items: res.data.response.postcards,
                page: 1,
                loadMore: loadMore
            });

            setWaitContent(false);
        }).catch((err) => {
            console.log(err);
            setWaitContent(false);
        });
    }

    function clickLoadMore() {
        setWaitLoadMore(true);

        axios.get("/api/postcards.get", {
            params: {...reqParams.current, offset: count * p.page, count, sex: activeSex}
        }).then((res) => {
            setWaitContent(false);

            let loadMore = true;
            if(res.data.response.postcards.length < count) {
                loadMore = false;
            }

            setP({
                items: [...p.items, ...res.data.response.postcards],
                page: p.page + 1,
                loadMore: loadMore
            });

            setWaitLoadMore(false);
        }).catch((err) => {
            console.log(err);
            setWaitContent(false);
        });
    }


    let modalHash = "modal-";
    if(params.idCat) {
        modalHash += categories[params.idCat]['id'];
    }
    if(params.idSub) {
        modalHash += "-" + categories[params.idCat]['subCategories'][params.idSub]['id'];
    }
    if(activeOther !== null) {
        modalHash += "-" + categories[params.idCat]['subCategories'][params.idSub]['other'][activeOther]['id'];
    }

    return (
        <Panel id={id}>
            <PanelHeader left={<PanelHeaderBack onClick={() => navigate(-1)} />}>
                Категория
            </PanelHeader>

            {modalPost? (
                <ModalPost title="Открытка" onClose={() => setModalPost(false)} modalHash={modalHash}>
                    <ModalPostcard openedCard={openedCard} setOpenedCard={setOpenedCard} />
                </ModalPost>
            ) : null}

            {arrOther.length && arrOther[0]['sex']? (
                <Group>
                    <SubnavigationBar mode="fixed">
                        <SubnavigationButton selected={activeSex === 0} onClick={() => clickSex(0)}>
                            Все
                        </SubnavigationButton>
                        <SubnavigationButton selected={activeSex === 2} onClick={() => clickSex(2)}>
                            Мужские
                        </SubnavigationButton>
                        <SubnavigationButton selected={activeSex === 1} onClick={() => clickSex(1)}>
                            Женские
                        </SubnavigationButton>
                    </SubnavigationBar>
                </Group>
            ) : null}

            {arrOther.length? (
                <Group>
                    <SubnavigationBar>
                        <SubnavigationButton selected={activeOther === null} onClick={() => clickOther(null)}>
                            Все
                        </SubnavigationButton>
                        {arrOther.map((e, i) => {
                            if(activeSex) {
                                if(activeSex === e.sex) {
                                    return (
                                        <SubnavigationButton key={e.id}
                                                             selected={activeOther === i}
                                                             onClick={() => clickOther(i)}
                                        >
                                            {e.title}
                                        </SubnavigationButton>
                                    );
                                }
                            } else {
                                return (
                                    <SubnavigationButton key={e.id}
                                                         selected={activeOther === i}
                                                         onClick={() => clickOther(i)}
                                    >
                                        {e.title}
                                    </SubnavigationButton>
                                );
                            }
                        })}
                    </SubnavigationBar>
                </Group>
            ) : null}

            <Group>
                {waitContent? (
                    <Spinner size="large" style={{ margin: "20px 0" }} />
                ) : null}

                <div className="cardsWrapper">
                    {p.items.map((e, i) => {
                        return (
                            <div key={e.id} className="cardWrap" onClick={() => clickPostCard(i)}>
                                <div className="cardWrapContent">
                                    <div className="imageWrap">
                                        <div className="viewImage"
                                             style={{backgroundImage: "url("+ getPathFiles(`/sys/files/postcards/${e.img}`) +")"}}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {p.loadMore && !waitContent? (
                    <Div style={{display: "flex", justifyContent: "center"}}>
                        <Button sizeY="compact" loading={waitLoadMore} onClick={clickLoadMore}>Загрузить еще</Button>
                    </Div>
                ) : null}
            </Group>
        </Panel>
    );
}

export default Category;