import {Avatar, CellButton, Group, Panel, PanelHeader, SimpleCell} from "@vkontakte/vkui";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import moment from 'moment';
import 'moment/locale/ru';

import './Calendar.scss';
import {Icon16Chevron, Icon24Add, Icon24ChevronDown} from "@vkontakte/icons";
import AccordionItem from "../../UI/AccordionItem";
import {useNavigate} from "react-router-dom";
import isset from "../../utils/isset";

const Calendar = (props) => {
    const {id} = props;

    const [items, setItems] = useState([]);
    const [showHideItems, setShowHideItems] = useState(false);

    const months = useSelector(state => state.sys.months);

    const navigate = useNavigate();

    // console.log(moment('3/8/2022',"MM-DD-YYYY").format('D H:mm:ss'));
    // console.log(moment('3/8',"MM-DD").format('D H:mm:ss'));
    // console.log(moment().format("dd"));
    // console.log(moment().format("M"), moment().format("D"));

    const thisDay = +moment().format("D");
    const thisMonth = +moment().format("M");

    useEffect(() => {
        let rows = [];
        let itemsRow = [];
        let c = [];
        let holidays = [];
        let hideMonth = false;

        for(let i = 1; i <= 12; i++) {
            hideMonth = false;
            
            let daysInMonth = moment(`${i}`,"MM").daysInMonth();
            let dW = moment(`${i}-1`,"MM-DD").weekday();
            
            if(i < thisMonth) {
                hideMonth = true;
            }

            for(let k = 0; k <= 6; k++) {
                if(dW === k) {
                    break;
                }

                itemsRow.push({
                    day: 0,
                    empty: true
                });
            }

            for(let k = 1; k <= daysInMonth; k++) {
                let holiday = false;
                for(let j = 0; j < months[i].length; j++) {
                    if(months[i][j]['day'] === k) {
                        holiday = true;
                        holidays.push(months[i][j]);
                    }
                }

                itemsRow.push({
                    day: k,
                    empty: false,
                    holiday: holiday,
                });

                if(k === daysInMonth) {
                    let h = 7 - itemsRow.length;
                    for(let j = 0; j < h; j++) {
                        itemsRow.push({
                            day: 0,
                            empty: true
                        });
                    }
                }

                if(itemsRow.length > 6 || k === daysInMonth) {
                    rows.push(itemsRow);
                    itemsRow = [];
                }
            }

            c.push({
                open: 0,
                rows: rows,
                holidays: holidays,
                hideMonth: hideMonth
            });
            rows = [];
            itemsRow = [];
            holidays = [];
        }

        setItems(c);
    }, [months, thisMonth]);

    function clickAccordion(idArr) {
        let arr = [...items];
        arr[idArr]['open'] = !arr[idArr]['open'];
        setItems(arr);
    }

    function clickLink(arr) {
        if(isset(arr['idArrCat']) && isset(arr['idArrSub'])) {
            navigate(`/category/${arr['idArrCat']}/${arr['idArrSub']}`);

            return;
        }

        if(isset(arr['idArrCat'])) {
            navigate(`/category/${arr['idArrCat']}`);
        }
    }

    return (
        <Panel id={id}>
            <PanelHeader>
                Календарь
            </PanelHeader>

            <Group>
                <div className="CalendarWrap accordionWrap">
                    {items.length && items[0]['hideMonth'] && !showHideItems? (
                        <CellButton centered onClick={() => setShowHideItems(true)}>
                            Показать прошедшие
                        </CellButton>
                    ) : null}
                    {items.map((m, i) => {
                        return (
                            <div key={i} className="CalendarBody" style={{display: m.hideMonth && !showHideItems? "none" : "block"}}>
                                <div className="CalendarHeader">
                                    {moment(`${i + 1}`,"MM").format("MMMM")}
                                </div>

                                <div className="CalendarRow CalendarRow_weekDays">
                                    <div className="CalendarItem">Пн</div>
                                    <div className="CalendarItem">Вт</div>
                                    <div className="CalendarItem">Ср</div>
                                    <div className="CalendarItem">Чт</div>
                                    <div className="CalendarItem">Пт</div>
                                    <div className="CalendarItem">Сб</div>
                                    <div className="CalendarItem">Вс</div>
                                </div>

                                {m.rows.map((row, r) => {
                                    return (
                                        <div key={r} className="CalendarRow">
                                            {row.map((e, k) => {
                                                if(e.empty) {
                                                    return <div key={k} className="CalendarItem"/>;
                                                }

                                                if(e.holiday) {
                                                    return (
                                                        <div key={k} className="CalendarItem">
                                                            <div className="CalendarItem_active">
                                                                {e.day}
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                let style = {};
                                                if(i + 1 === thisMonth && e.day === thisDay) {
                                                    style = {color: "#841818", fontWeight: "bold"};
                                                }

                                                return (
                                                    <div key={k} className="CalendarItem" style={style}>{e.day}</div>
                                                )
                                            })}
                                        </div>
                                    );
                                })}

                                <AccordionItem open={m.open} header={
                                    <SimpleCell before={<Avatar size={36} style={{background: "#719e4f"}}>
                                                            <span style={{color: "#fff"}}>
                                                                <Icon24Add width={18} height={18}/>
                                                            </span>
                                                        </Avatar>}
                                                after={<Icon24ChevronDown/>}
                                                onClick={() => clickAccordion(i)}
                                    >
                                        Праздники
                                    </SimpleCell>
                                }>
                                    {m.holidays.map((e) => {
                                        moment(`${e.month}/${e.day}`,"MM-DD").format('DD.MM.YYYY')
                                        return (
                                            <SimpleCell multiline key={e.id}
                                                        before={<Avatar size={28} style={{background: "#5181ab"}}>
                                                                <span style={{color: "#fff"}}>
                                                                    <Icon16Chevron width={18} height={18}/>
                                                                </span>
                                                        </Avatar>}
                                                        onClick={() => clickLink(e)}
                                                        description={moment(`${e.month}-${e.day}`,"MM-DD").format('DD.MM.YYYY')}
                                            >
                                                {e.title}
                                            </SimpleCell>
                                        );
                                    })}
                                </AccordionItem>
                            </div>
                        );
                    })}
                </div>
            </Group>
        </Panel>
    );
}

export default Calendar;