function imageLoad(path, cors) {
    return new Promise((resolve, reject) => {
        let a = new Image();
        a.onload = () => {
            resolve(a);
        };
        a.onerror = () => {
            reject();
        }
        if(cors) {
            a.crossOrigin = 'Anonymous';
        }
        a.src = path;
    });
}

export default imageLoad;