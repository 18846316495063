import {combineReducers} from 'redux';

import sysReducer from './sys/reducers';
import userReducer from './user/reducers';
import mainPostcardsReducer from './mainPostcards/reducers';
import vkReducer from './vk/reducers';

const rootReducer = combineReducers({
    sys: sysReducer,
    vk: vkReducer,
    user: userReducer,
    mainPostcards: mainPostcardsReducer,
});

export default rootReducer;