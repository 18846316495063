import bridgeReal from '@vkontakte/vk-bridge';
import mockMethod from './methods';

let bridge = null;

if(process.env.NODE_ENV === 'production') {
    bridge = bridgeReal;
} else {
    bridge = {};

    bridge.supports = (method) => {
        if(method === "VKWebAppSubscribeStoryApp") {
            return true;
        }

        return false;
    };

    let subscribers = [];

    bridge.subscribe = (fn) => {
        subscribers.push(fn);
    };
    bridge.unsubscribe = (fn) => {
        let index = subscribers.indexOf(fn);

        if (index > -1) {
            subscribers.splice(index, 1);
        }
    };

    bridge.send = (method, params) => {
        let content = {};
        if(method === 'VKWebAppSetViewSettings') {
            content = {
                result: true
            };
        }
        if(method === 'VKWebAppShowStoryBox') {
            content = {
                result: true
            };
        }
        if(method === 'VKWebAppGetAuthToken') {
            content = {
                "access_token": "safsaKJJKsdksk_dsfsd7asmsdaJGYKLMNmjHJbLnHKM",
                "scope": ''
            };
        }
        if(method === 'VKWebAppCallAPIMethod') {
            content = {
                "request_id": "111",
                "response": mockMethod(params.method, params['params'])
            };
        }
        if(method === 'VKWebAppGetUserInfo') {
            content = {
                "id": 30333918,
                "first_name": "Сергей",
                "last_name": "Качалка",
                "sex": 2,
                "city": {
                    "id": 2256,
                    "title": "Горловка"
                },
                "country": {
                    "id": 2,
                    "title": "Украина"
                },
                "photo_100": "https://sun1-5.userapi.com/c834304/v834304001/101214/WVtscxWYlCI.jpg?ava=1",
                "photo_200": "https://sun1-11.userapi.com/c834304/v834304001/101213/g7TwDpG11dM.jpg?ava=1",
                "timezone": 3
            };
        }
        if(method === 'VKWebAppGetFriends') {
            content = {
                "users": [
                    {
                        "id": 2884043,
                        "first_name": "Albert",
                        "last_name": "Usmanov"
                    }
                ]
            };
        }
        if(method === 'VKWebAppShowWallPostBox') {
            content = {
                "post_id": 21312
            };
        }
        if(method === 'VKWebAppGetAds') {
            content = {
                title: 'Заголовок',
                domain: 'vk.com',
                trackingLink: 'https://vk.com',
                ctaText: 'Перейти',
                advertisingLabel: 'Реклама',
                iconLink: 'https://sun9-7.userapi.com/c846420/v846420985/1526c3/ISX7VF8NjZk.jpg',
                description: 'Описание рекламы',
                ageRestriction: 14,
                statistics: [
                    { url: '', type: 'playbackStarted' },
                    { url: '', type: 'click' }
                ]
            };
        }

        return new Promise((resolve, reject) => {
            // if(method === 'VKWebAppGetAuthToken') {
            //     reject();
            // }
            resolve(content);
        })
    };
}

export default bridge;