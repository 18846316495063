function imageResize(img, maxWidth, maxHeight, index, cb) {
    let canvasCopy = document.createElement("canvas")
    let copyContext = canvasCopy.getContext("2d")
    let ratio = 1

    if(img.width > maxWidth) {
        ratio = maxWidth / img.width
    }
    else if(img.height > maxHeight) {
        ratio = maxHeight / img.height
    }

    canvasCopy.width = img.width * ratio
    canvasCopy.height = img.height * ratio
    copyContext.drawImage(img, 0, 0, img.width * ratio, img.height * ratio);

    let newImage = new Image();
    newImage.onload = () => {
        if(cb) {
            cb(newImage, index);
        }
    }

    newImage.src = canvasCopy.toDataURL();
}

export default imageResize;