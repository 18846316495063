import React, {useEffect, useRef, useState} from 'react';
import './index.scss';
import {
    useAdaptivity,
    AppRoot,
    SplitLayout,
    SplitCol,
    ViewWidth,
    View,
    Panel,
    PanelHeader,
    Header,
    Group,
    SimpleCell,
    Epic,
    Tabbar,
    TabbarItem,
    useAppearance,
    Cell,
    usePlatform,
    ANDROID,
    IOS,
    AppearanceProvider,
    AdaptivityProvider, ConfigProvider
} from "@vkontakte/vkui";
import {useLocation, useNavigate} from "react-router-dom";
import {
    Icon24CalendarOutline,
    Icon24ClockOutline,
    Icon24Filter,
    Icon24Home, Icon24List,
    Icon28CalendarOutline
} from "@vkontakte/icons";
import Main from "./Panels/Main/Main";
import axios from "./utils/axios";
import {useDispatch, useSelector} from "react-redux";
import {setCategories, setHolidays, setSys} from "./store/sys/actions";
import {userUpdate} from "./store/user/actions";
import Categories from "./Panels/Categories/Categories";
import bridge from "./helpers/bridge";
import Category from "./Panels/Categories/Category";
import ViewPost from "./Panels/ViewPost/ViewPost";
import Front from "./Panels/Front/Front";
import CategoriesAdmin from "./Panels/CategoriesAdmin/CategoriesAdmin";
import {updateMainPostcards} from "./store/mainPostcards/actions";
import Calendar from "./Panels/Calendar/Calendar";
import {userVkUpdate} from "./store/vk/actions";
import isset from "./utils/isset";

function App(props) {
    const [waitContent, setWaitContent] = useState(true);
    // const [appearance, setAppearance] = useState("light");

    const { viewWidth } = useAdaptivity();

    const isDesktop = viewWidth >= ViewWidth.TABLET;
    const location = useLocation();
    const navigate = useNavigate();

    const platform = usePlatform();

    const timerAds = useRef();

    const holidays = useSelector(state => state.sys.holidays);
    const popout = useSelector(state => state.sys.popout);
    const appearance = useSelector(state => state.sys.appearance);
    const showAds = useSelector(state => state.sys.showAds);

    const lastTab = useRef('main');
    const left = useRef('');

    const dispatch = useDispatch();

    let activeStory = props.activeStory? props.activeStory : 'main';
    const tabbarItems = ['main', 'front', 'categories', 'calendar'];
    if(tabbarItems.find((e) => e === activeStory)) {
        lastTab.current = activeStory;
    }

    useEffect(() => {
        bridge.send('VKWebAppInit');

        bridge.subscribe(({ detail: { type, data }}) => {
            if (type === 'VKWebAppUpdateConfig') {
                dispatch(setSys({
                    appearance: data.appearance ? data.appearance : 'light'
                }));

                if(platform === ANDROID || platform === IOS) {
                    if(data.appearance && data.appearance === "light") {
                        bridge.send("VKWebAppSetViewSettings", {"status_bar_style": "dark"});
                    } else {
                        bridge.send("VKWebAppSetViewSettings", {"status_bar_style": "light"});
                    }
                }
            }
        });
    }, [platform, dispatch]);

    useEffect(() => {
        // bridge.send('VKWebAppInit').then();

        async function run() {
            let vkUser = await bridge.send('VKWebAppGetUserInfo');
            let res = await axios.post("/api/sys.firstRequest");

            if(res.data.error) {
                return;
            }

            if(!res.data.response || !res.data.response.user) {
                return;
            }

            dispatch(userUpdate(res.data.response.user));
            dispatch(userVkUpdate(vkUser));
            // dispatch(setCategories(res.data.response.categories));
            // dispatch(setHolidays(res.data.response.holidays));
            dispatch(updateMainPostcards({
                items: res.data.response.postcards,
                idSub: res.data.response.id_sub
            }));
            dispatch(setSys({
                months: res.data.response.months,
                categories: res.data.response.categories,
                holidays: res.data.response.holidays,
            }));

            setWaitContent(false);
        }

        run();
    }, [dispatch]);

    useEffect(() => {
        // if(showAds) {
        //     timerAds.current = setTimeout(() => {
        //         bridge.send("VKWebAppShowNativeAds", {ad_format: "reward", use_waterfall: true}).then((data) => {
        //             dispatch(setSys({
        //                 showAds: false
        //             }));
        //         }).catch((error) => {
        //             dispatch(setSys({
        //                 showAds: false
        //             }));
        //         });
        //     }, 7000);
        // }
    }, [dispatch, showAds]);

    function clickEpicTabbar(path) {
        if(path === location.pathname) {
            window.scrollTo(0, 0);

            return;
        }

        left.current = '';
        navigate(path);
    }

    function clickHoliday(i) {
        left.current = `/category/${holidays[i]['idArrCat']}/${holidays[i]['idArrSub']}`;
        lastTab.current = '';

        navigate(`/category/${holidays[i]['idArrCat']}/${holidays[i]['idArrSub']}`);
    }

    if(waitContent) {
        return (
            <div className="LoadingStart" style={appearance === 'dark'? {background: "#3d3d3d"} : {}}>
                <div className="lds-ripple">
                    <div/>
                    <div/>
                </div>
            </div>
        );
    }

    return (
        <ConfigProvider appearance={appearance}>
            <AdaptivityProvider>
        <AppRoot>
            <SplitLayout header={<PanelHeader separator={false} />} style={{ justifyContent: "center" }} popout={popout}>
                {isDesktop? (
                    <SplitCol fixed width={280} maxWidth={280}>
                        <PanelHeader/>
                        <Panel id="nav">
                            <Group>
                                <Cell disabled={lastTab.current === 'main'}
                                      style={lastTab.current === 'main'? {backgroundColor: "var(--button_secondary_background)", borderRadius: 8} : {}}
                                      onClick={() => clickEpicTabbar('/main')}
                                >
                                    Открытки
                                </Cell>
                                <Cell disabled={lastTab.current === 'front'}
                                      style={lastTab.current === 'front'? {backgroundColor: "var(--button_secondary_background)", borderRadius: 8} : {}}
                                      onClick={() => clickEpicTabbar('/front')}
                                >
                                    Ближайшие
                                </Cell>
                                <Cell disabled={lastTab.current === 'categories'}
                                      style={lastTab.current === 'categories'? {backgroundColor: "var(--button_secondary_background)", borderRadius: 8} : {}}
                                      onClick={() => clickEpicTabbar('/categories')}
                                >
                                    Категории
                                </Cell>
                                <Cell disabled={lastTab.current === 'calendar'}
                                      style={lastTab.current === 'calendar'? {backgroundColor: "var(--button_secondary_background)", borderRadius: 8} : {}}
                                      onClick={() => clickEpicTabbar('/calendar')}
                                >
                                    Календарь
                                </Cell>
                            </Group>
                            <Group>
                                {[1,2,3,4,5].map((e, i) => {
                                    return (
                                        <Cell key={i} style={left.current === `/category/${holidays[i]['idArrCat']}/${holidays[i]['idArrSub']}`? {backgroundColor: "var(--button_secondary_background)", borderRadius: 8} : {}}
                                              onClick={() => clickHoliday(i)}
                                        >
                                            {holidays[i].title}
                                        </Cell>
                                    );
                                })}
                            </Group>
                        </Panel>
                    </SplitCol>
                ) : null}

                <SplitCol animate={!isDesktop}
                          spaced={viewWidth >= ViewWidth.SMALL_TABLET}
                          // width={viewWidth >= ViewWidth.TABLET? "560px" : "100%"}
                          maxWidth={isDesktop? "560px" : "100%"}
                >
                    <Epic activeStory={activeStory} tabbar={!isDesktop? (
                        <Tabbar>
                            <TabbarItem
                                onClick={() => clickEpicTabbar('/main')}
                                selected={lastTab.current === 'main'}
                                text="Открытки"
                            ><Icon24Home /></TabbarItem>
                            <TabbarItem
                                onClick={() => clickEpicTabbar('/front')}
                                selected={lastTab.current === 'front'}
                                text="Ближайшие"
                            ><Icon24ClockOutline /></TabbarItem>
                            <TabbarItem
                                onClick={() => clickEpicTabbar('/categories')}
                                selected={lastTab.current === 'categories'}
                                text="Категории"
                            ><Icon24List /></TabbarItem>
                            <TabbarItem
                                onClick={() => clickEpicTabbar('/calendar')}
                                selected={lastTab.current === 'calendar'}
                                text="Календарь"
                            ><Icon24CalendarOutline /></TabbarItem>
                        </Tabbar>
                    ) : null
                    }>
                        <View id="main" activePanel="main">
                            <Main id="main"/>
                        </View>
                        <View id="categories" activePanel="categories">
                            <Categories id="categories"/>
                        </View>
                        <View id="category" activePanel="category">
                            <Category id="category"/>
                        </View>
                        <View id="view-post" activePanel="view-post">
                            <ViewPost id="view-post"/>
                        </View>
                        <View id="front" activePanel="front">
                            <Front id="front"/>
                        </View>
                        <View id="calendar" activePanel="calendar">
                            <Calendar id="calendar"/>
                        </View>
                        <View id="categories-admin" activePanel="categories-admin">
                            <CategoriesAdmin id="categories-admin"/>
                        </View>
                    </Epic>
                </SplitCol>
            </SplitLayout>
        </AppRoot>
            </AdaptivityProvider>
        </ConfigProvider>
    );
}

export default App;
