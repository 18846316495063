import * as types from './types';

let initState = {};

export default function userReducer(state = initState, action) {
    switch (action.type) {
        case types.USER_UPDATE:
            return {...state, ...action.payload};
        default:
            return state;
    }
}