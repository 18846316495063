import {Avatar, Cell, Group, Header, Panel, PanelHeader, SimpleCell} from "@vkontakte/vkui";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import AccordionItem from "../../UI/AccordionItem";

import { Icon24ChevronDown } from '@vkontakte/icons';
import {setCategories} from "../../store/sys/actions";

import { Icon24Add } from '@vkontakte/icons';
import { Icon16Chevron } from '@vkontakte/icons';

const Categories = (props) => {
    const {id} = props;

    const categories = useSelector(state => state.sys.categories);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    function clickCategory(idArr) {
        let c = [...categories];

        if(!c[idArr]['open']) {
            c[idArr]['open'] = 1;
        } else {
            c[idArr]['open'] = 0;
        }

        dispatch(setCategories(c));
    }

    function clickLink(idArr, idSub) {
        if(idArr !== null && idArr !== undefined && idSub !== null && idSub !== undefined) {
            navigate(`/category/${idArr}/${idSub}`);

            return;
        }

        if(idArr !== null && idArr !== undefined) {
            navigate(`/category/${idArr}`);
        }
    }

    return (
        <Panel id={id}>
            <PanelHeader>
                Категории
            </PanelHeader>
            <Group>
                <div className="accordionWrap">
                    {categories.map((e, i) => {
                        if(e.subCategories.length) {
                            return (
                                <AccordionItem key={e.id} open={e.open} header={
                                    <SimpleCell before={<Avatar size={36} style={{background: "#719e4f"}}>
                                                            <span style={{color: "#fff"}}>
                                                                <Icon24Add width={18} height={18}/>
                                                            </span>
                                                        </Avatar>}
                                                after={<Icon24ChevronDown/>}
                                                onClick={() => clickCategory(i)}
                                    >
                                        {e.title}
                                    </SimpleCell>
                                }>
                                    <div style={{margin: "3px 4px 3px 12px"}}>
                                        {e.subCategories.map((sub, k) => {
                                            return (
                                                <SimpleCell key={sub.id}
                                                            before={<Avatar size={28} style={{background: "#5181ab"}}>
                                                                <span style={{color: "#fff"}}>
                                                                    <Icon16Chevron width={18} height={18}/>
                                                                </span>
                                                            </Avatar>}
                                                            onClick={() => clickLink(i, k)}
                                                >
                                                    {sub.title}
                                                </SimpleCell>
                                            )
                                        })}
                                    </div>
                                </AccordionItem>
                            );
                        }

                        return (
                            <SimpleCell key={e.id} before={<Avatar size={36} />}
                                        onClick={() => clickLink(i)}
                            >
                                {e.title}
                            </SimpleCell>
                        )
                    })}
                </div>
            </Group>
        </Panel>
    );
}

export default Categories;