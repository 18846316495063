import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import "@vkontakte/vkui/dist/vkui.css";

import {Route, Routes, BrowserRouter} from 'react-router-dom';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import rootReducer from './store/reducers';

const container = document.getElementById('root');
const root = createRoot(container);

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

// import('vconsole').then(({default: VConsole}) => {
//     new VConsole();
// });

//appearance="dark"
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<App activeStory="main"/>} />
                <Route path="main" element={<App activeStory="main"/>} />
                <Route path="categories" element={<App activeStory="categories"/>} />
                <Route path="category/:idCat" element={<App activeStory="category"/>}>
                    <Route path=":idSub" element={<App activeStory="category"/>}/>
                </Route>
                <Route path="view-post" element={<App activeStory="view-post"/>} />
                <Route path="front" element={<App activeStory="front"/>} />
                <Route path="calendar" element={<App activeStory="calendar"/>} />
                <Route path="categories-admin" element={<App activeStory="categories-admin"/>} />
            </Routes>
        </BrowserRouter>
    </Provider>
);