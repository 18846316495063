import {useEffect, useRef, useState} from "react";

const AccordionItem = (props) => {
    const [clicked, setClicked] = useState();
    const contentEl = useRef(null);

    useEffect(() => {
        setClicked(props.open);
    }, [props.open]);

    const handleToggle = () => {
        // setClicked((prev) => !prev);
    };

    return (
        <div className="aItemWrapper">
            <div className="aItemTitle" onClick={handleToggle}>
                {props.header}
            </div>
            <div
                ref={contentEl}
                className="aItemBody"
                style={clicked && contentEl.current? { height: contentEl.current.scrollHeight } : { height: "0px" }}
            >
                {props.children}
            </div>
        </div>
    );
}

export default AccordionItem;