import * as types from './types';

export function setPopout(name) {
    return async (dispatch) => {
        dispatch({
            type: types.SYS_SET_POPOUT,
            payload: name,
        });
    }
}

export function setSys(name) {
    return {
        type: types.SYS_SET_SYS,
        payload: name,
    }
}

export function setCategories(name) {
    return {
        type: types.SYS_SET_CATEGORIES,
        payload: name,
    }
}

export function setHolidays(name) {
    return {
        type: types.SYS_SET_HOLIDAYS,
        payload: name,
    }
}