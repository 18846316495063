import {Group, Panel, PanelHeader, PanelHeaderBack} from "@vkontakte/vkui";
import {useNavigate} from "react-router-dom";

const ViewPost = (props) => {
    const {id} = props;

    const navigate = useNavigate();

    return (
        <Panel id={id}>
            <PanelHeader left={<PanelHeaderBack onClick={() => navigate(-1)} />}>
                Открытка
            </PanelHeader>

            <Group>
                dd
            </Group>
        </Panel>
    );
}

export default ViewPost;