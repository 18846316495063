import {Avatar, Group, Panel, PanelHeader, SimpleCell} from "@vkontakte/vkui";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Icon16Chevron} from "@vkontakte/icons";
import isset from "../../utils/isset";

const Front = (props) => {
    const {id} = props;

    const holidays = useSelector(state => state.sys.holidays);

    const navigate = useNavigate();

    function clickLink(i) {
        if(isset(holidays[i]['idArrCat']) && isset(holidays[i]['idArrSub'])) {
            navigate(`/category/${holidays[i]['idArrCat']}/${holidays[i]['idArrSub']}`);

            return;
        }

        if(isset(holidays[i]['idArrCat'])) {
            navigate(`/category/${holidays[i]['idArrCat']}`);
        }
    }

    return (
        <Panel id={id}>
            <PanelHeader>
                Ближащие
            </PanelHeader>
            <Group>
                {holidays.map((e, i) => {
                    return (
                        <SimpleCell key={e.id}
                                    before={<Avatar size={28} style={{background: "#5181ab"}}>
                                        <span style={{color: "#fff"}}>
                                            <Icon16Chevron width={18} height={18}/>
                                        </span>
                                    </Avatar>}
                                    onClick={() => clickLink(i)}
                                    multiline
                        >
                            {e.title}
                        </SimpleCell>
                    )
                })}
            </Group>
        </Panel>
    );
}

export default Front;

// Помним, скорбим
// С новосельем
// С выходом на пенсию