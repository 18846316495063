import * as types from './types';

let initState = {
    items: [],
    page: 1,
    loadMore: true,
    idSub: null
};

export default function mainPostcardsReducer(state = initState, action) {
    switch (action.type) {
        case types.MAIN_POSTCARDS_UPDATE:
            return {...state, ...action.payload};
        default:
            return state;
    }
}