import * as types from './types';

let initState = {
    user: {},
    accessToken: null,
    scope: "",
    accessTokenFail: false,
};

export default function vkReducer(state = initState, action) {
    switch (action.type) {
        case types.USER_VK_UPDATE:
            return {
                ...state,
                user: action.payload
            };
        case types.VK_SET_ACCESS_TOKEN:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}