import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";

import './ModalPost.scss';
import {PanelHeader, PanelHeaderBack} from "@vkontakte/vkui";
// import {MdClose} from "react-icons/md";


const ModalPost = (props) => {
    const {title, onClose, modalHash} = props;

    const el = useRef(document.createElement('div'));
    const closeHandler = useRef(onClose);

    useEffect(() => {
        const element = el.current;
        document.getElementById('root').appendChild(element);

        window.history.pushState(null, "", "#" + modalHash);

        return () => {
            document.getElementById('root').removeChild(element);
        }
    }, [modalHash]);

    useEffect(() => {
        function popstate() {
            if(closeHandler.current) {
                closeHandler.current();
            }
        }

        window.addEventListener('popstate', popstate);

        return () => {
            window.removeEventListener('popstate', popstate);
        }
    }, []);

    function modalClose() {
        window.history.back();
    }

    return(
        ReactDOM.createPortal(
            <div className="ModalPost">
                <div className="ModalPostContent">
                    <PanelHeader left={<PanelHeaderBack onClick={modalClose} />}>
                        {title}
                    </PanelHeader>
                    {props.children}
                </div>
            </div>,
            el.current
        )
    );
};

ModalPost.defaultProps = {
    modalHash: "modalOne"
};

ModalPost.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
    modalHash: PropTypes.string
};

export default ModalPost;