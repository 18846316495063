import {useDispatch, useSelector} from "react-redux";
import {Avatar, Cell, Group, Panel, PanelHeader} from "@vkontakte/vkui";
import {setCategories} from "../../store/sys/actions";
import axios from "../../utils/axios";
import {Icon24ChevronDown} from "@vkontakte/icons";

const CategoriesAdmin = (props) => {
    const {id} = props;

    const categories = useSelector(state => state.sys.categories);

    const dispatch = useDispatch();

    function onDragFinish({from, to}) {
        const _list = [...categories];
        _list.splice(from, 1);
        _list.splice(to, 0, categories[from]);

        dispatch(setCategories(_list));

        let allIds = [];
        _list.forEach((e) => {
            allIds.push(e.id);
        });

        axios.get("/api/postcards.categoriesSaveAdmin", {
            params: {
                ids: allIds
            }
        }).then((res) => {

        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <Panel id={id}>
            <PanelHeader>
                Категории
            </PanelHeader>
            <Group>
                {categories.map((e, i) => {
                    return (
                        <Cell draggable onDragFinish={onDragFinish} key={e.id}
                              before={<Avatar size={36} />}
                              after={e.subCategories.length? <Icon24ChevronDown/> : null}
                        >
                            {e.title}
                        </Cell>
                    );
                })}
            </Group>
        </Panel>
    );
}

export default CategoriesAdmin;