import {
    Avatar,
    Button,
    ButtonGroup,
    Div,
    FormItem,
    Group,
    Header,
    ScreenSpinner,
    Snackbar,
    Spinner,
    Textarea
} from "@vkontakte/vkui";
import getPathFiles from "../../helpers/getPathFiles";
import {useEffect, useRef, useState} from "react";
import axios from "../../utils/axios";
import bridge from "../../helpers/bridge";
import imageLoad from "../../helpers/imageLoad";
import imageResize from "../../helpers/imageResiza";
import {useDispatch, useSelector} from "react-redux";
import {apiRequest, userVkUpdate} from "../../store/vk/actions";
import {setPopout, setSys} from "../../store/sys/actions";
import {Icon16Done, Icon24Error} from "@vkontakte/icons";
import config from "../../helpers/config";
import {userUpdate} from "../../store/user/actions";

const ModalPostcard = ({openedCard, setOpenedCard}) => {
    const [waitContent, setWaitContent] = useState(true);
    const [text, setText] = useState("");
    const [snackbar, setSnackbar] = useState(null);

    const dispatch = useDispatch();

    const vk = useSelector(state => state.vk);
    const user = useSelector(state => state.user);
    const showAds = useSelector(state => state.sys.showAds);

    const timerAds = useRef();
    
    
    useEffect(() => {
        if(!showAds) {
            return;
        }

        bridge.send("VKWebAppShowNativeAds", {ad_format: "reward", use_waterfall: true}).then((data) => {
            dispatch(setSys({
                showAds: false
            }));
        }).catch((error) => {
            dispatch(setSys({
                showAds: false
            }));
        });
    }, [dispatch, showAds]);

    useEffect(() => {
        if(openedCard.id_photo) {
            setWaitContent(false);
            return;
        }

        axios.get("/api/postcards.viewPost", {
            params: {
                id: openedCard.id
            }
        }).then((res) => {
            if(res.data.error) {
                setWaitContent(false);
                return;
            }

            let card = {...openedCard};
            card.id_photo = res.data.response.id_photo;
            card.id_group = res.data.response.id_group;
            console.log(card);

            setOpenedCard(card);
            setWaitContent(false);
        }).catch((err) => {
            console.log(err);
            setWaitContent(false);
        });
    }, [openedCard, setOpenedCard]);

    function displayError(message) {
        setSnackbar(
            <Snackbar
                layout="vertical"
                onClose={() => setSnackbar(null)}
                before={<Avatar size={24} style={{ background: '#e78484' }}><Icon24Error fill="#fff" width={14} height={14} /></Avatar>}
            >
                {message}
            </Snackbar>
        );
    }

    function clickSendWall(isSelf) {
        dispatch(setPopout(<ScreenSpinner />));

        let photo = 'photo-' + openedCard['id_group'] + '_' + openedCard['id_photo'];
        let t = text + "\n#Открытки -  https://vk.com/app6777371";

        if(isSelf) {
            bridge.send("VKWebAppShowWallPostBox", {
                "message": t,
                attachments: photo
            }).then((data) => {
                dispatch(setPopout(null));
                // displayError("Не удалось отправить открытку");
            }).catch((error) => {
                dispatch(setPopout(null));
                displayError("Не удалось отправить открытку 1");
                console.log(error);
            });

            return;
        }

        bridge.send("VKWebAppGetFriends").then((data) => {
            console.log(data);
            if(!data['users'] || !data['users'].length) {
                // TODO error

                dispatch(setPopout(null));
                displayError("Не удалось отправить открытку 2");
                return;
            }

            bridge.send("VKWebAppShowWallPostBox", {
                owner_id: data['users'][0]['id'],
                "message": t,
                attachments: photo
            }).then((data) => {
                dispatch(setPopout(null));
                // displayError("Не удалось отправить открытку 3");
            }).catch((error) => {
                dispatch(setPopout(null));
                if(error.error_data && error.error_data.error_code) {
                    if(error.error_data.error_code === 1 || error.error_data.error_code === 214) {
                        displayError("Пользователь закрыл доступ к стене");
                    } else if(error.error_data.error_code === 4) {
                        displayError("Открытка не отправлена");
                    } else {
                        displayError("Не удалось отправить открытку 4");
                    }
                }
                // console.log(error);
            });
        }).catch((error) => {
            console.log(error);
            dispatch(setPopout(null));
            displayError("Не удалось отправить открытку 5");
        });
    }

    function onChangeText(e) {
        setText(e.target.value);
    }

    function clickSendStory() {
        dispatch(setPopout(<ScreenSpinner />));

        imageLoad(getPathFiles(`/sys/files/postcards/${openedCard.img}`)).then((img) => {
            imageResize(img, 1000, 1000, 0, (img) => {
                bridge.send("VKWebAppShowStoryBox", {
                    "background_type": "image",
                    // "locked": true,
                    "blob": img.src,
                    "request_id": (Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)).toString()
                }).then((data) => {
                    dispatch(setPopout(null));
                }).catch((e) => {
                    dispatch(setPopout(null));
                });
            });
        }).catch((err) => {
            console.log(err);
        })
    }


    function clickSaveAlbum() {
        dispatch(setPopout(<ScreenSpinner />));

        function upload(accessToken, album) {
            apiRequest('photos.getUploadServer', {album_id: album.id}, accessToken, (server) => {
                let formData = new FormData();
                formData.append("path", openedCard.img);
                formData.append("url", server.upload_url);
                formData.append("id_album", album.id);

                axios.post('/api/sys.saveInAlbum', formData).then((e) => {
                    apiRequest('photos.save',{
                        album_id: album.id,
                        photos_list: e.data.response.res.photos_list,
                        server: e.data.response.res.server,
                        hash: e.data.response.res.hash,
                        caption: `Открытка из приложения https://vk.com/app${config.appId}`
                    }, accessToken, (res2) => {
                        // console.log(res2);
                        dispatch(setPopout(null));

                        setSnackbar(
                            <Snackbar
                                layout="vertical"
                                onClose={() => setSnackbar(null)}
                                before={<Avatar size={24} style={{ background: '#6190e0' }}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
                            >
                                Картинка добавлена в альбом.
                            </Snackbar>
                        );
                    }, (e) => {
                        dispatch(setPopout(null));

                        displayError("Не удалось сохранить картинку");
                    });
                }).catch(() => {
                    dispatch(setPopout(null));

                    displayError("Не удалось сохранить картинку, возникла ошибка получения данных");
                });
            }, (err) => {
                dispatch(setPopout(null));

                displayError("Не удалось получить сервер для загрузки картинки");
            });
        }

        function createAlbum(accessToken) {
            apiRequest('photos.getAlbums', {
                album_ids: user.id_album,
            }, accessToken, (myAlbum) => {
                if(!myAlbum.items || !myAlbum.items.length || !user.id_album) {
                    apiRequest('photos.createAlbum', {
                        title: "Открытки VK Mini Apps",
                        // description: "Коллажи"
                    }, accessToken, (album) => {
                        dispatch(userUpdate({
                            id_album: album.id
                        }));

                        upload(accessToken, album);
                    }, () => {
                        dispatch(setPopout(null));

                        displayError("Не удалось получить создать альбом");
                    });

                    return;
                }

                upload(accessToken, myAlbum.items[0]);
            }, () => {
                dispatch(setPopout(null));

                displayError("Не удалось получить альбом фотографий");
            });
        }

        if(!vk.accessToken) {
            bridge.send("VKWebAppGetAuthToken", {'app_id': config.appId, 'scope': 'photos'}).then((data) => {
                dispatch(userVkUpdate({
                    accessToken: data['access_token'],
                    scope: data.scope,
                    accessTokenFail: false
                }));

                createAlbum(data['access_token']);
            }).catch((error) => {
                dispatch(userVkUpdate({
                    accessTokenFail: true
                }));

                dispatch(setPopout(null));

                displayError("Не удалось получить права доступа");
            });
        } else {
            createAlbum(vk.accessToken);
        }
    }

    return (
        <>
            <Div>
                <div className="mWrapper">
                    <div className="mImageWrap">
                        <div className="mContent">
                            <div className="viewImage"
                                 style={{backgroundImage: "url("+ getPathFiles(`/sys/files/postcards/${openedCard.img}`) +")"}}/>
                        </div>
                    </div>
                </div>
            </Div>

            {!waitContent? (
                <>
                    <Group mode="plain" header={<Header>На стену</Header>}>
                        <FormItem>
                            <Textarea
                                cols={4}
                                placeholder="Тест сообщения"
                                onChange={onChangeText}
                            />
                        </FormItem>
                        <Div>
                            <ButtonGroup mode="horizontal" gap="m" stretched>
                                <Button size="l" stretched sizeY="compact" onClick={() => clickSendWall()}>
                                    Другу
                                </Button>
                                <Button size="l" stretched sizeY="compact" onClick={() => clickSendWall(true)}>
                                    Себе
                                </Button>
                            </ButtonGroup>
                        </Div>
                    </Group>

                    <Group mode="plain">
                        <Div>
                            <ButtonGroup mode="horizontal" gap="m" stretched>
                                <Button size="l" stretched sizeY="compact" onClick={clickSendStory}>
                                    Историю
                                </Button>
                                <Button size="l" stretched sizeY="compact" onClick={clickSaveAlbum}>
                                    В альбом
                                </Button>
                            </ButtonGroup>
                        </Div>
                    </Group>
                </>
            ) : (
                <Group mode="plain">
                    <Spinner size="large" style={{ margin: "20px 0" }} />
                </Group>
            )}

            {snackbar}
        </>
    );
}

export default ModalPostcard;