import {Avatar, Button, Div, Group, Header, Panel, PanelHeader, SimpleCell} from "@vkontakte/vkui";
import ModalPost from "../../UI/ModalPost/ModalPost";
import {useDispatch, useSelector} from "react-redux";
import getPathFiles from "../../helpers/getPathFiles";
import React, {useState} from "react";
import ModalPostcard from "../Categories/ModalPostcard";
import axios from "../../utils/axios";
import {updateMainPostcards} from "../../store/mainPostcards/actions";

const Main = (props) => {
    const {id} = props;

    const [modalPost, setModalPost] = useState(false);
    const [openedCard, setOpenedCard] = useState([]);
    const [waitLoadMore, setWaitLoadMore] = useState(false);

    const postcards = useSelector(state => state.mainPostcards);

    const dispatch = useDispatch();

    const count = 30;

    function clickPostCard(idArr) {
        console.log(postcards.items[idArr]);
        setOpenedCard(postcards.items[idArr]);
        setModalPost(true);
    }

    function clickLoadMore() {
        setWaitLoadMore(true);

        axios.get("/api/postcards.get", {
            params: {id_sub: postcards.idSub, offset: count * postcards.page, count}
        }).then((res) => {
            let loadMore = true;
            if(res.data.response.postcards.length < count) {
                loadMore = false;
            }

            dispatch(updateMainPostcards({
                items: [...postcards.items, ...res.data.response.postcards],
                page: postcards.page + 1,
                loadMore: loadMore
            }));

            setWaitLoadMore(false);
        }).catch((err) => {
            console.log(err);
            setWaitLoadMore(false);
        });
    }

    return (
        <Panel id={id}>
            <PanelHeader>
                Открытки
            </PanelHeader>

            {modalPost? (
                <ModalPost title="Открытка" onClose={() => setModalPost(false)}>
                    <ModalPostcard openedCard={openedCard} setOpenedCard={setOpenedCard} />
                </ModalPost>
            ) : null}

            <Group>
                <div className="cardsWrapper">
                    {postcards.items.map((e, i) => {
                        return (
                            <div key={e.id} className="cardWrap" onClick={() => clickPostCard(i)}>
                                <div className="cardWrapContent">
                                    <div className="imageWrap">
                                        <div className="viewImage"
                                             style={{backgroundImage: "url("+ getPathFiles(`/sys/files/postcards/${e.img}`) +")"}}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {postcards.loadMore? (
                    <Div style={{display: "flex", justifyContent: "center"}}>
                        <Button sizeY="compact" loading={waitLoadMore} onClick={clickLoadMore}>Загрузить еще</Button>
                    </Div>
                ) : null}
            </Group>
        </Panel>
    );
}

export default Main;