import * as types from './types';
import bridge from "../../helpers/bridge";
import config from "../../helpers/config";

const API_VERSION = '5.103';

export async function apiRequest(method, params = {}, accessToken = '', successCallback = undefined, errorCallback = undefined) {
    params['access_token'] = accessToken;
    if (params['v'] === undefined) {
        params['v'] = API_VERSION;
    }

    try {
        const res = await bridge.send('VKWebAppCallAPIMethod', {
            'method': method,
            'params': params,
            // 'request_id': requestId
        });
        successCallback(res['response']);
    } catch (e) {
        errorCallback(e);
    }
}

export const setAccessToken = (scope) => async (dispatch) => {
    try {
        const token = await bridge.send('VKWebAppGetAuthToken', {'app_id': config.appId, 'scope': scope});
        dispatch({
            type: types.VK_SET_ACCESS_TOKEN,
            payload: {
                accessToken: token['access_token'],
                scope: token.scope,
                accessTokenFail: false
            }
        });
    } catch (e) {
        dispatch({
            type: types.VK_SET_ACCESS_TOKEN_FAIL,
            payload: {
                accessTokenFail: true,
            }
        });
    }
};

export const userVkUpdate = (name) => {
    return {
        type: types.USER_VK_UPDATE,
        payload: name,
    }
};