import axiosy from 'axios';
import $_GET from "../helpers/getParams";
/**
 * Стаднартная настройка для ajax запросов
 */
let axios = axiosy.create({
    baseURL: process.env.NODE_ENV === 'production'? "" : "http://postcards-lol.local/",
    params: $_GET
});

export default axios;
